import React from 'react';
import styled from 'styled-components';
import Section from '../components/section';
import Layout from '../components/layout';

const StyledSection = styled.div`
  min-height: 32em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2em;
`
const Blog = () => (
  <Layout>
    <StyledSection>Under construction</StyledSection>
  </Layout>
)

export default Blog;